import React from 'react';
import Slider from "react-slick";
import ProductionSlide from './ProductionSlide';

const settings = {
  autoplay: true,
  infinite: true,
  accessibility: false,
  arrows: false,
  draggable: false,
  fade: true,
  pauseOnFocus: false,
  pauseOnHover: false,
  autoplaySpeed: 4500,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const ProductionSlider = ({ slides }) => (
  <Slider {...settings}>
    {slides.map(slide => <ProductionSlide key={slide.id} {...slide} />)}
  </Slider>
);

export default ProductionSlider;
